import "./App.css";
import CardArch from "../src/components/CardArch";
import CardUrb from "../src/components/CardUrb";
import CardHis from "../src/components/CardHis";
import CardTech from "../src/components/CardTech";

function App() {
  return (
    <div className="App">
      <header>
        <h1>PYTANIA EGZAMINACYJNE</h1>
      </header>
      <div className="mainBody">
        <CardArch />
        <CardUrb />
        <CardHis />
        <CardTech />
      </div>
    </div>
  );
}

export default App;
