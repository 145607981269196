export const architektura = [
  "1. Podaj jedną z definicji architektury.",
  "2. Jaka jest różnica między „przestrzenią” a „miejscem” - podaj definicję obu pojęć?",
  "3. Co to jest warszawska szkoła architektury i jakie są jej podstawowe cechy?",
  "4.Podaj podstawowe normatywne standardy z „Warunków technicznych…” odnoszące się do domu jednorodzinnego.",
  "5. Wymień ikony XX-wiecznego domu jednorodzinnego.",
  "6. Określ miejsce etyki zawodu architekta w ogólnej problematyce filozoficznej.",
  "7. Jak i gdzie zapisane są zasady etyki zawodu architekta i kto nadzoruje ich przestrzeganie?",
  "8. Wymień podstawowe elementy odpowiedzialności architekta wobec klienta.",
  "9. Jaka cechą wyróżnia się architektura sakralna od innych typów architektury użyteczności publicznej?",
  "10. Wymień podstawowe uniwersalne znaki i symbole stosowane w przestrzeni sakralnej.",
  "11. Wymień przykłady ikon świątyń XX wieku.",
  "12. Widoczność i słyszalność, – na czym polega ich różna rola w kształtowaniu widowni dla różnych rodzajów widowisk?",
  "13. Uzasadnij różnorodność w kształtowaniu przestrzeni wewnętrznej w budynkach użyteczności publicznej na przykładzie dwóch realizacji warszawskich.",
  "14. Jakie są ogólne zasady projektowania miejsc parkingowych w obiektach użyteczności publicznej?",
  "15. Nasłonecznienie i zacienianie, – co trzeba o tym wiedzieć przy projektowaniu architektury?",
  "16. Omów przepisy projektowania poziomych i pionowych dróg ewakuacyjnych w budynkach użyteczności publicznej.",
  "17. „Funkcja/Materiał/Forma” – przedstaw swój punkt widzenia na ich współzależność w projektowaniu architektonicznym.",
  "18. Schody w architekturze – jak warunki techniczne projektowania wpływają na ich wyraz plastyczny?",
  "19. Kiedy budownictwo staje się architekturą? Kiedy architektura staje się scenografią? – przedstaw swój punkt widzenia.",
  "20. Jakie znasz rozwiązania architektoniczne udostępniające obiekty użyteczności publicznej osobom niepełnosprawnym ruchowo?",
  "21. Jak uwarunkowania lokalizacji mogą wpływać na rozwiązanie architektoniczne budynku?",
  "22. Funkcja a wyraz architektoniczny budynku – przedstaw swój punkt widzenia.",
  "23. Co składa się na pojęcie „Typ budynku”? Jakie nowe typy budynków pojawiły się w wieku XX?",
  "24. Podaj cechy architektury określanej jako monumentalna.",
  "25. Jak oceniasz rolę architekta we współczesnym społeczeństwie?",
  "26. Kto jest twoim ulubionym architektem (-tką) i dlaczego?",
  "27. Podaj podstawowe relacje zachodzące pomiędzy wnętrzem otoczeniem i za pomocą jakich środków są one realizowane. Przytocz przykłady.",
  "28. Podaj na przykładzie znanych ci obiektów, jakie typy relacji formalnoprzestrzennych występują pomiędzy wnętrzem a wyrazem zewnętrznym architektury?",
  "29. Jakie decyzje projektowe mają decydujący wpływ na kształtowanie tzw. klimatu wnętrza?",
  "30. Jaką rolę we wnętrzach odgrywa kolor?",
  "31. Czym jest zrównoważony rozwój i jakie zagadnienia z nim związane występują w architekturze?",
  "32. Omów proekologiczne urządzenia i elementy budowlanoprzestrzenne, które mogą mieć wpływ na architekturę przemysłową?",
  "33. Scharakteryzuj podstawowe zasady kształtowania przestrzeni i formy w projektowaniu architektury przemysłowej i wielkoprzestrzennej.",
  "34. Podaj główne elementy krajobrazu wiejskiego.",
  "35. Podaj, jakie są cechy charakterystyczne osiedla wiejskiego.",
  "36. Jaka jest geneza osadnictwa wiejskiego?",
  "37. Podaj główne elementy zagrody wiejskiej i jej rolę w krajobrazie.",
  "38. Co to jest architektura regionalna?",
  "39. Czemu służy i co zawiera schemat funkcjonalny budynku?",
  "40. Scharakteryzuj pojęcia wielofunkcyjności i elastyczności w obiektach architektonicznych.",
  "41. Czym jest strefa obsługiwana i obsługująca w budynkach użyteczności publicznej? Podaj przykłady.",
  "42. Forma wynika z funkcji czy funkcja wypełnia formę? Które z tych twierdzeń jest Twoim zdaniem prawdziwe i dlaczego?",
  "43. Scharakteryzuj koncepcje architektoniczne wychodzące od typologii i od idei.",
  "44. Wyjaśnij zasady projektowania pochylni dla ruchu pieszego i kołowego.",
  "45. Wyjaśnij zasadę wyznaczania krzywej widoczności obiektów widowiskowych.",
  "46. Wyjaśnij zasadę rozliczania stopni schodów.",
  "47. Co to jest światło rozproszone, pośrednie i bezpośrednie? Jakie są ich właściwości i zastosowania?",
  "48. Określ, czym jest przestrzeń publiczna, półpubliczna, prywatna i półprywatna, jakie są ich cechy wyróżniające? Podaj przykłady tych przestrzeni.",
  "49. Wymień trzy cechy architektury wg Witruwiusza.",
  "50. Wymień pięć zasad architektury nowoczesnej sformułowanych przez Le Corbusiera?",
  "51. Fasada i elewacja, wyjaśnij te pojęcia i ich różnice.",

  "52. Wyjaśnij pojęcia: osiowość, symetria i równowaga w kompozycji architektonicznej.",
  "53. Wymień czynniki integracji obiektu architektonicznego z otoczeniem, określ hierarchię ich ważności.",
  "54. Wyjaśnij podobieństwa i różnice pojęć skala i proporcje w architekturze.",
  "55. Wymień czynniki spoistości formy architektonicznej.",
  "56. Wyjaśnij pojęcia: wzór kulturowy, pastisz, stylizacja w architekturze, podaj przykłady warszawskie.",
  "57. Wyjaśnij czy zasady estetyczne są niezmienne?",
  "58. Wyjaśnij rozróżnienie: figura – tło, podaj przykłady w architekturze.",
  "59. Przedstaw obiektywne i subiektywne rozumienie proporcji w architekturze.",
  "60. Wyjaśnij pojęcia: moda i styl w architekturze.",
  "61. Proszę wskazać podstawowe cechy dobrze rozwiązanej izby lekcyjnej.",
  "62. Co oznacza pojęcie: „szkoła z programem środowiskowym”?",
  "63. Proszę wymienić i pokrótce omówić typy szatni spotykanych w szkołach.",
  "64. Jakimi cechami powinien charakteryzować się plac przedwejściowy do szkoły?",
  "65. Proszę uszeregować wg wielkości boiska do gier: piłka ręczna, koszykówka, piłka siatkowa, piłka nożna, tenis ziemny. Podaj wymiary chociażby jednego z nich.",
  "66. Co oznaczają pojęcia: „korytarz stopy bosej” i „korytarz stopy obutej” oraz dla jakich obiektów sportowych mają zastosowanie?",
  "67. Podaj obowiązujące parametry wymiarowe pomieszczeń mieszkalnych (minimalne szerokości, powierzchnie i kubatury) wraz z funkcjonalną argumentacją tych wymiarów.",
  "68. Przedstaw obowiązujące zasady (wraz z wymaganymi parametrami) nasłonecznienia mieszkań (w śródmieściu i na terenach peryferyjnych) a także oświetlenia dziennego udynków (problem ich przesłaniania) i wnętrz pomieszczeń przeznaczonych do pracy (np. izby lekcyjnej).",
  "69. Jakie są obowiązujące minimalne wysokości pomieszczeń z uwagi na sposób ich użytkowania?",
  "70. Podaj obowiązujące parametry schodów (szerokości biegu, maksymalna ilość stopni w biegu, maksymalna wysokość stopnia, wysokość i usytuowanie balustrady) dla: - domów mieszkalnych, - budynków użyteczności publicznej, - budynków służby zdrowia.",
  "71. Wymień znane ci zasadnicze układy funkcjonalne mieszkań z analizą ich funkcjonalnych i przestrzennych zalet i wad.",
  "72. Wymień znane ci systemy komunikacji w budynkach mieszkalnych wielorodzinnych niskich, średniowysokich i wysokich – z analizą ich funkcjonalnych i ekonomicznych zalet i wad.",
  "73. Jakie znasz sposoby architektoniczne ograniczania niekorzystnego oddziaływania na wnętrze promieniowania słonecznego (przegrzewanie, olśnienie, nadmierna transmisja energii termicznej)?",
  "74. Wymień cechy charakterystyczne dla architektury obiektów wystawowych.",
  "75. Proszę omówić podstawowe zasady oświetlenia ekspozycji.",
  "76. Jakie cechy łączą obiekty przemysłowe i wystawiennicze?",
  "77. Proszę omówić, w jakich warunkach pawilon wystawowy może być równocześnie eksponatem, a w jakich warunkach nie powinien nim być?",
  "78. Proszę omówić podstawowe cechy obiektów mobilnych, przeznaczonych do wielokrotnego montażu.",
  "79. Jakie obiekty użyteczności publicznej mogą być wykorzystywane okresowo dla celów wystawienniczych i targowych?",
  "80. Omów elementy kompozycji krajobrazu ze szczególnym uwzględnieniem składników roślinnych.",
  "81. Podaj charakterystykę „zielonego dachu” – omów znane technologie wykonywania takich dachów, stosowane rośliny. Podaj parę przykładów realizacji zielonych dachów.",
  "82. Omów typy obszarów chronionych w Polsce, podstawowe funkcje tych obszarów i dopuszczalne sposoby ich użytkowania i zabudowy.",
  "83. Jakie warunki dostępności dla osób niepełnosprawnych powinny spełniać budynki wielorodzinne i ich otoczenie w świetle wymagań wynikających z obowiązujących przepisów?",
  "84. Jakie warunki dostępności dla osób niepełnosprawnych powinny spełniać budynki użyteczności publicznej i ich otoczenie w świetle wymagań wynikających z obowiązujących przepisów?",
  "85. Jakie obowiązują wymagania dotyczące miejsc postojowych, w tym dla samochodów osób niepełnosprawnych względem otaczającej zabudowy i granic działki?",
  "86. Co wiesz o zasadach projektowania wejścia głównego do budynku użyteczności publicznej?",
  "87. Pierzeja uliczna – jak rozumiesz ten problem architektoniczny?",
  "88. Co wiesz o projektowaniu dojścia i dojazdu do budynku użyteczności publicznej?",
  "89. Co wiesz o projektowaniu dojścia i dojazdu do budynku mieszkalnego wielorodzinnego?",
  "90. Jaki ma wpływ elewacja kurtynowa na formę budynku?",
  "91. Jak rozumiesz atrium jako przestrzeń społeczną w budynku użyteczności publicznej?",
  "92. Jakie znasz problemy projektowania szklanego dachu w budynku użyteczności publicznej?",
  "93. Co wiesz o komunikacji pionowej w budynkach?",
  "94. Jak lokalizacja węzła cieplnego, wentylatorni i stacji trafo wpływa na rozwiązania funkcjonalno-przestrzenne budynku przeznaczonego na pobyt ludzi?",
  "95. Jak zastosowanie klimatyzacji w budynku może wpływać na rozwiązania architektoniczne budynku biurowego?",
  "96. Co wiesz o poziomych drogach ewakuacyjnych w budynkach?",
  "97. Co wiesz o pionowych drogach ewakuacyjnych w budynkach?",
  "98. Co wiesz o osłonach przeciwsłonecznych ścian i dachów szklanych?",
  "99. Rampy zjazdowe do i w garażu wielopoziomowym – wymagania normatywu technicznego.",
  "100. Stanowiska postojowe a siatka słupów w garażu wielokondygnacyjnym.",
  "101. Przedstaw normatywne odległości między budynkami – problematyka ppoż.",
  "102. Jakie są ogólne zasady projektowania akustyki wnętrz salwidowiskowych?",
  "103. Przedstaw zasady projektowania ochrony pomieszczeń przed hałasem wewnętrznym i zewnętrznym.",
  "104. Jaki ma wpływ wysokość budynku na problematykę ppoż. w projektowaniu architektonicznym?",
  "105. Jaki wpływ ma zamierzona aranżacja przestrzeni biurowej na wybór systemu konstrukcyjnego budynku?",
  "106. Jakie powinny być cechy domu jednorodzinnego wielopokoleniowego?",
  "107. Jakie powinny być cechy taniego mieszkania?",
  "108. Parking i podjazd do budynku mieszkalnego wielorodzinnego i użyteczności publicznej, – jakie są wymagania normatywne w ich projektowaniu?",
  "109. Projektowanie drzwi w aspekcie ewakuacji pomieszczeń i budynku, – jakie są wymagania normatywne?",
  "110. Projektowanie toalet ogólnodostępnych w budynkach użyteczności publicznej, – jakie są wymagania normatywne?",
  "111. Jakie są standardy techniczne projektowania toalet dla osób niepełnosprawnych ruchowo?",
  "112. Jakie znasz ogólne zasady kształtowania przestrzeni handlowych?",
  "113. Schody wewnętrzne, schody zewnętrzne, – jakie są wymagania normatywne projektowania?",
  "114. Jakie powinny być cechy architektury budynku ratusza i jakimi środkami można je uzyskać?",
  "115. Jakie powinny być cechy architektury budynku świątyni i jakimi środkami można je uzyskać?",
  "116. Jakie powinny być cechy architektury budynku szkoły i jakimi środkami można je uzyskać?",
  "117. Wymień znane Ci historyczne przykłady architektury wirtualnej nie realizowanej za pomocą technik komputerowych.",
  "118. W jakich dziedzinach znajduje praktyczne zastosowanie kształtowanie przestrzeni wirtualnej?",
  "119. Jak rozumiesz relację między światem realnym a wirtualnym, i jakie przykłady architektoniczne ilustrują te odniesienia?",
  "120. Co to jest „budynek inteligentny”?",
  "121. Co to jest architektura medialna, podaj przykłady?",
  "122. Na czym polega ewolucja warsztatu architekta, podaj przykłady dzieł i nurtów, których powstanie wiąże się z wprowadzeniem nowych narzędzi i technik projektowych?",
  "123. Jakich głównych rodzajów oprogramowania używa w pracy architekt? Wymień przynajmniej 5 i podaj przykłady",
  "124. Jaka jest różnica między zbiorem informacji, bazą danych i bazą wiedzy?",
  "125. Wymień znane Ci formy przekazu informacji stosowane w projektowaniu architektonicznym.",
  "126. Jakie formaty plików komputerowych mają zastosowanie w pracy architekta i jakie informacje przenoszą?",
  "127. Ilu programów i ilu formatów plikowych należałoby użyć dla pełnego przedstawienia projektu architektonicznego?",
  "128. Co to jest i czemu służy project extranet?",
  "129. Zdefiniuj i omów pojęcia: „przekaz multimedialny”; „przekaz interaktywny”.",
  "130. Co to jest model? Podaj postacie i typy modeli stosowanych w projektowaniu architektonicznym.",
  "131. Na czym polega modelowanie parametryczne w architekturze?",
  "132. Co to jest „rapid prototyping” i jakie ma zastosowanie w projektowaniu form przemysłowych i projektowaniu architektonicznym?",
  "133. Co to jest CAD i jakie budynki mogły powstać dzięki zastosowaniu technik komputerowych? (Podaj przykłady budynków, których realizacja na pewno wymagała zastosowania technik CAD).",
  "134. Co to jest: Zintegrowany System CAD?",
  "135. Co oznacza skrót angielski BIM?",
  "136. Wymień przynajmniej 5 zespołów projektowych lub twórców stosujących w swej pracy modelowanie parametryczne i elementy przestrzeni wirtualnej.",
  "137. Wymień przynajmniej 3 pozycje książkowe dotyczące zastosowań technologii informacji w projektowaniu architektonicznym i urbanistycznym.",
  "138. Jakie znasz czasopisma architektoniczne dostępne on-line?",
  "139. Jakie jest Twoim zdaniem najbardziej awangardowe czasopismo architektoniczne w Europie?",
  "140. Wymień co najmniej 5 wydziałów architektury na świecie przodujących na polu zastosowań technologii informacji w projektowaniu.",
];

export const urbanistyka = [
  "141. Wymień grupy związków, jakie łączą miasto z jego otoczeniem, podaj przykłady takich rozwiązań?",
  "142. Na czym polegała idea „miasta ogrodu” i kto ją opracował? Wymień znane ci przykłady.",
  "143. Wylicz działania człowieka, które mogą negatywnie oddziaływać na środowisko przyrodnicze?",
  "144. Wymień cele polityki mieszkaniowej, jakie były realizowane w różnych okresach przez rządy państw i przedstaw, jakie były osiągane efekty tych polityk?",
  "145. Na czym polega rola klimatyczna a na czym użytkowa zieleni w mieście, przy omawianiu przedstaw parę przykładów?",
  "146. Podaj, jakie działania gospodarcze wiążą się z trzema podstawowymi sektorami gospodarki. Powiedz, który z tych sektorów charakteryzuje się dziś najliczniejszym zatrudnieniem?",
  "147. Wyjaśnij, na czym polega zasada zrównoważonego rozwoju?",
  "148. Jaki dokument planistyczny wyraża politykę przestrzenną gminy i z jakich zasadniczych części powinien on się składać?",
  "149. Wymień instytucje uczestniczące w procedurze planistycznej dotyczącej miejscowego planu zagospodarowania przestrzennego.",
  "150. Na czym polega teoria progów rozwojowych, jakie mogą występować progi w rozwoju miasta, podaj przykłady?",
  "151. Czym różni się prognoza od programu rozwoju, jakie znasz metody prognozowania?",
  "152. Wymień podstawowe elementy obrazu miasta według Kevina Lyncha lub Kazimierza Wejcherta.",
  "153. Co to jest intensywność zabudowy i według jakiego wzoru się ją oblicza?",
  "154. Kto przygotowuje projekt miejscowego planu zagospodarowania przestrzennego, kto go sporządza, kto uzgadnia i opiniuje i kto stwierdza jego zgodność z prawem?",
  "155. Jaka decyzja administracyjna zastępuje, według polskiego prawa, ustalenia miejscowego planu zagospodarowania przestrzennego dnośnie terenu inwestycji?",
  "156. Jakim celom służy linia rozgraniczająca w miejscowym planie zagospodarowania przestrzennego?",
  "157. Wymień znane Ci nowe miasto w Europie i opisz cechy jego struktury przestrzennej.",
  "158. Jakie znasz trzy typy przestrzeni miejskiej wyróżnione cechami społecznymi?",
  "159. Na czym polega dyfuzja rozprzestrzeniania się zjawisk rozwojowych, podaj przykłady zjawisk rozwojowych podatnych na taką dyfuzję?",
  "160. Wymień znane Ci kategorie techniczne ulic i podaj zasadnicze różnice miedzy nimi.",
  "161. Co to jest infrastruktura techniczna miasta, wymień podstawowe jej rodzaje?",
  "162. Jak można dzielić usługi w mieście, podaj parę przykładów takich podziałów?",
  "163. Po połączeniu obu części Berlina, w pasie rozdzielających go murów, zrealizowano kilka wielkich zespołów urbanistycznych, wymień, jakie to były zespoły i jakim funkcjom służą?",
  "164. Jakie znasz typy środków komunikacji zbiorowej w mieście, ułóż je w kolejności możliwości przewozowych?",
  "165. Co to jest śródmieście, jakie są jego cechy funkcjonalne i krajobrazowe?",
  "166. Co to jest detal urbanistyczny i wymień elementy zagospodarowania rozwiązywane z użyciem tego detalu?",
  "167. Co to jest przestrzeń publiczna, wymień podstawowe cechy takich przestrzeni?",
  "168. Co to jest superjednostka, kto zaproponował taką formę zabudowy i dla jakich rozwiązań urbanistycznych była ona wzorcem?",
  "169. Jakie były powody organizacji terenów mieszkaniowych w jednostki sąsiedzkie i według jakich kryteriów są one wyróżniane?",
  "170. Przedstaw, jakie są warunki zaistnienia związków sąsiedzkich na terenach zabudowy mieszkaniowej?",
  "171. Co to jest zabytek urbanistyki, wymień kilka znanych Ci przykładów?",
  "172. Wylicz podstawowe kryteria oceny zabytkowego zespołu urbanistycznego.",
  "173. Wymień i podaj przykłady rozwiązań pozwalających na odciążenie śródmieścia miasta z nadmiernego ruchu samochodowego.",
  "174. Na czym polega ciągłość w planowaniu przestrzennym, jaka jest kolejność podejmowania poszczególnych decyzji dotyczących działań planistycznych?",
  "175. Wymień, jakie elementy zagospodarowania wykazuje się w inwentaryzacji urbanistycznej?",
  "176. Kiedy przestrzeń przekształca się w miejsce? Wymień najważniejsze miejsca w środowisku zamieszkania.",
  "177. Podaj sposoby orientowania się w przestrzeni.",
  "178. Wymień cztery sposoby profesjonalnej prezentacji przestrzeni.",
  "179. Wymień głównych realizatorów polityki mieszkaniowej i podaj zakresy ich działań.",
  "180. Wymień rozwiązania urbanistyczne stosowane przy wzroście natężenia ruchu samochodowego.",
  "181. Jakimi cechami wyróżnia się miasto wśród innych jednostek osadniczych?",
  "182. Podaj różnice pomiędzy centrum a śródmieściem miasta.",
  "183. Co może zapewnić trwały i zrównoważony rozwój miasta?",
  "184. Co to jest rewitalizacja i jakie cele ma realizować?",
  "185. Na stworzenie jakich warunków przestrzennych jest ukierunkowane planowanie przestrzenne?",
  "186. Jaką rolę pełnią opracowania planistyczne w urbanistyce?",
  "187. Czym jest polityka przestrzenna? Wymień najważniejsze instrumenty polityki przestrzennej.",
  "188. Wymień cechy kulturowej przestrzeni publicznej w mieście.",
  "189. Czym zajmuje się Urbanistyka?",
];

export const historia = [
  "190. Opisz typy świątyń greckich i sposób ich sytuowania.",
  "191. Wymień rodzaje budowli grobowych w starożytnym Egipcie.",
  "192. Wymień porządki architektoniczne w architekturze Starożytnej Grecji.",
  "193. Co to jest atrium? Podaj przykłady zastosowania.",
  "194. Opisz cechy układu przestrzennego rzymskich bazylik sądowych.",
  "195. Opisz architekturę rzymskiego Panteonu.",
  "196. Co to znaczy addycyjność brył, separatyzm wnętrz, budowanie według modułu kwadratu oraz „system wiązany” pól sklepiennych? Proszę podać przykłady w architekturze romańskiej.",
  "197. Czy gotyckie katedry francuskie miały układ przestrzenny bazylikowy czy halowy?",
  "198. Jak wyglądała dyspozycja funkcjonalno-przestrzenna (w zależności od kondygnacji) w renesansowym pałacu miejskim?",
  "199. Kiedy i gdzie pojawia się typ kościoła jezuickiego? Proszę podać przykład włoski i polski.",
  "200. Podaj typy i przykłady budowli, w których zastosowano w XIX w. przekrycie oparte na konstrukcji żelaznej.",
  "201. Scharakteryzuj podstawowe cechy „urbanistyki hippodamejskiej”.",
  "202. Omów układ przestrzenny obozu rzymskiego.",
  "203. Na przykładzie Warszawy omów podstawowe cechy układu przestrzennego miasta w dobie średniowiecza.",
  "204. Elementy przedlokacyjne w strukturze miast polskich – podaj przykłady.",
  "205. Scharakteryzuj układ przestrzenny miast portowych doby średniowiecza – podaj polski przykład.",
  "206. Zamość – „idealne miasto” doby renesansu – podaj podstawowe cechy kompozycji przestrzennej.",
  "207. Scharakteryzuj podstawowe cechy kompozycji urbanistycznej doby baroku.",
  "208. Wyjaśnij rolę jurydyk w rozwoju przestrzennym Warszawy.",
  "209. Scharakteryzuj wielkie XVIII-wieczne osie kompozycyjne Warszawy.",
  "210. Podaj najważniejsze cechy przebudowy Paryża w XIX wieku.",
  "211. Scharakteryzuj przemiany miast w dobie industrializacji.",
  "212. Scharakteryzuj pojęcia: „miasto liniowe”, „miasto-ogród”, „miasto przemysłowe”",
  "213. Wymień kryteria decydujące o uznaniu budowli za zabytek.",
  "214. Wymień formy ochrony prawnej zabytków i procedury ich wdrażania według obowiązujących regulacji prawnych.",
  "215. Konserwacja, restauracja – podaj definicje i przykłady prac konserwatorskich i restauratorskich.",
  "216. Odbudowa zabytku w świetle teorii konserwacji zabytków.",
  "217. Omów różnice w odbudowie Warszawy i Gdańska po II wojnie światowej.",
  "218. Jakie są obowiązki i procedury w przypadku odkrycia w trakcie robót budowlanych przedmiotu o przypuszczalnych wartościach zabytkowych?",
  "219. Destrukcyjny wpływ wilgoci na zabytkową budowlę murową – wymień typowe uszkodzenia.",
  "220. Wymień polskie obiekty wpisane na Listę Dziedzictwa Światowego UNESCO.",
  "221. Scharakteryzuj początki budownictwa i architektury na ziemiach polskich do poł. XI w. (pierwsze katedry w Poznaniu i Gnieżnie, palatium książęce na Ostrowie Lednickim)",
  "222. Scharakteryzuj cechy architektury romańskiej XII w. na terenie Polski (kolegiaty, kościoły parafialne)",
  "223. Klasztory cystersów i zakonów żebraczych w XIII w. na terenie Polski (Wąchock, Sandomierz)",
  "224. Scharakteryzuj wczesny gotyk na Śląsku (kaplice w Trzebnicy i Wrocławiu, katedra we Wrocławiu)",
  "225. Pełny gotyk w Małopolsce i na Śląsku w XIV w. – podaj najważniejsze cechy (Kraków i Wrocław)",
  "226. Katedry w Wielkopolsce XIV-pocz. XV w. (Gniezno i Poznań)",
  "227. Systemy obronne miasta średniowiecznego na terenie Polski - podaj najważniejsze cechy (mur, wieża, baszta, brama)",
  "228. Architektura gotycka XIV w. na obszarach północnych Polski; obronne klasztory krzyżackie - podaj najważniejsze cechy (Radzyń Chełmiński, Malbork)",
  "229. Późny gotyk XV/XVI w. w Gdańsku, na Mazowszu, na Śląsku - podaj najważniejsze cechy",
  "230. Renesans na Wawelu – 1 poł. XVI w. – podaj najważniejsze cechy",
  "231. Renesans na Mazowszu, renesans „lubelski” ok.1600 - podaj najważniejsze cechy",
  "232. Polska architektura sakralna 1 poł. XVII w. – jezuici, karmelici bosi, kameduli - podaj najważniejsze cechy",
  "233. Scharakteryzuj architekturę polskich zamków i pałaców nowożytnych, do ok.1650",
  "234. Pałace i wille w 2 poł. XVII w. na terenie Polski - podaj najważniejsze cechy",
  "235. Rezydencje w 1 poł. XVIII w. w Warszawie i na Podlasiu - podaj najważniejsze cechy",
  "236. Założenia sakralne w okresie późnego baroku na Śląsku - podaj najważniejsze cechy",
  "237. Scharakteryzuj mecenat Stanisława Augusta i ogrody romantyczne",
  "238. Neoklasycyzm Królestwa Kongresowego - podaj najważniejsze cechy",
  "239. Historyzm i eklektyzm w architekturze polskiej - podaj najważniejsze cechy",
  "240. Secesja, style narodowe – do 1914 r. w architekturze polskiej",
  "241. Architektura Warszawy w latach międzywojennych",
  "242. Architektura Warszawy w okresie realizmu socjalistycznego",
  "243. Szeregowa kamienica hanzeatycka XIII-XVIII w. na terenie Polski - podaj najważniejsze cechy",
  "244. Dom miejski i małomiasteczkowy na południowych terenach Polski XIIIXVIII w. – podaj najważniejsze cechy",
  "245. Kamienica czynszowa XIX-XX w. w Warszawie, Łodzi - podaj najważniejsze cechy",
  "246. Dwór polski; zespoły dworskie, zabudowa wsi – XIX w.- podaj najważniejsze cechy",
  "247. Drewniana architektura sakralna; świątynie różnych wyznań XV-XIX w. na terenie Polski - podaj najważniejsze cechy",
  "248. Kiedy i gdzie zastosowano (jako rozwiązanie prekursorskie) stalowe konstrukcje szkieletowe w budownictwie i jakie to miało konsekwencje dla kształtowania form architektonicznych – podaj przykłady systemów tych konstrukcji i budynków?",
  "249. Cele i działania CIAM. Podaj, w jakim czasie były zwoływane kongresy i ich tematykę.",
  "250. Odbudowa Warszawy 1945 –1955: omów skalę zniszczeń, założenia odbudowy, główne odbudowane obiekty i zespoły urbanistyczne.",
  "251. Wydział Architektury Politechniki Warszawskiej – Warszawska Szkoła Architektury.",
  "252. Wyjaśnij genezę hasła „architektura jest pochodną funkcji ” i przeprowadź jego krytykę jako założenia twórczego.",
  "253. Wskaż zasady kompozycyjne kształtowania formy monumentalnej w architekturze starożytnej.",
  "254. Omów sakralną architekturę wczesnochrześcijańską. Wskaż typy układów i odmiany regionalne.",
  "255. Omów niemiecką architekturę romańską i wpływ jej rozwiązań w architekturze polskiej.",
  "256. Scharakteryzuj architekturę sakralną włoskiego renesansu. Wskaż preferencje formy idealnej lub celowości liturgicznej w wyborze układu centralnego lub wzdłużnego.",
  "257. Przedstaw nawrót do idei i form architektury klasycznej w pierwszej połowie XIX wieku w Europie i Ameryce Północnej.",
  "258. Porównaj koncepcje modernizacji urbanistyki i architektury wielkich miast w II połowie XIX wieku: Paryża i Wiednia. Wymień przykłady wzniesionych tam wybitnych budowli w formach historyzmu, wskaż ich odniesienia do form przeszłości.",
  "259. Wymień główne ośrodki architektury secesyjnej, najwybitniejszych jej twórców i dzieła.",
  "260. Podaj przykłady XX-wiecznych osiedli mieszkaniowych wznoszonych w formach ekspresjonizmu i funkcjonalizmu. Opisz ich układy urbanistyczne i programy społeczne.",
  "261. Podaj przykłady interpretacji form historycznych w architekturze postmodernizmu.",
  "262. Scharakteryzuj dorobek antyku w tworzeniu podstaw cywilizacji miejskiej",
  "263. Rozwiń pojęcie „progu lokacyjnego” w urbanistyce średniowiecznej - określ znaczenie społeczne i przestrzenne nadania praw miejskich",
  "264. „Miasto idealne” a miasto rzeczywiste doby renesansu",
  "265. Archetypowe rozwiązania barokowej kompozycji Rzymu i Paryża i ich recepcja w urbanistyce europejskiej",
  "266. Podaj podstawowe cechy przebudowy polskich miast rezydencjonalnych w dobie Oświecenia, przytocz przykłady",
  "267. Utopijne koncepcje urbanistyczne początków doby industrializacji – geneza ich powstania, twórcy",
  "268. Wymień i porównaj przykłady wielkich przebudów i rozbudów stolic europejskich, w XIX wieku",
  "269. Omów koncepcje pionierów urbanistyki XX wieku pod kątem ich znaczenia dla rozwoju miasta współczesnego",
  "270. Przedstaw ewolucję dzielnicy centralnej Warszawy – od średniowiecza do wybuchu drugiej wojny światowej",
  "271. Europejska filozofia konserwacji początku XX wieku (Riegl, Dworak, Dehio).",
  "272. Pluralistyczna teoria konserwatorska dla XXI wieku.",
  "273. Podaj kryteria wpisu obiektów na Listę Dziedzictwa Światowego UNESCO.",
  "274. Dziedzictwo kultury, dobro kultury, zabytek – wskaż różnice w definicji pojęć.",
  "275. Opieka nad zabytkami a ochrona zabytków w świetle obowiązującego prawa.",
  "276. Krajobraz kulturowy – podaj definicję oraz narzędzia ochrony prawnej.",
  "277. Podaj zasady odbudowy Elbląga.",
  "278. Wymień kolejne fazy programu ochrony zabytku nieruchomego.",
  "279. Badania historyczno-architektoniczne i historyczno-konserwatorskie zabytkowej budowli – podaj ich zakres i znaczenie w ochronie zabytku.",
  "280. Scharakteryzuj początki budownictwa i architektury na ziemiach polskich do poł. XI wieku.",
  "281. Scharakteryzuj cechy architektury romańskiej XII w. na terenie Polski",
  "282. Klasztory Cystersów i zakonów żebraczych w XIII w. na terenie Polski",
  "283. Scharakteryzuj wczesny gotyk na Śląsku",
  "284. Pełny gotyk w Małopolsce i na Śląsku w XIV w. – podaj najważniejsze cechy",
  "285. Katedry w Wielkopolsce XIV- pocz. XV w.",
  "286. Systemy obronne miasta średniowiecznego na terenie Polski - podaj najważniejsze cechy.",
  "287. Architektura gotycka XIV w. na obszarach północnych Polski; obronne klasztory krzyżackie - podaj najważniejsze cechy",
  "288. Późny gotyk XV/XVI w. w Gdańsku, na Mazowszu, na Śląsku - podaj najważniejsze cechy",
  "289. Renesans na Wawelu – 1 poł.XVI w. – podaj najważniejsze cechy",
  "290. Renesans na Mazowszu, renesans „lubelski” ok.1600 - podaj najważniejsze cechy",
  "291. Polska architektura sakralna 1 poł.XVII w. – jezuici, karmelici bosi, kameduli - podaj najważniejsze cechy",
  "292. Scharakteryzuj architekturę polskich zamków i pałaców nowożytnych, do ok.1650",
  "293. Pałace i wille w 2 poł.XVII w. na terenie Polski - podaj najważniejsze cechy",
  "294. Rezydencje w 1 poł. XVIII w. w Warszawie i na Podlasiu - podaj najważniejsze cechy",
  "295. Założenia sakralne w okresie późnego baroku na Śląsku - podaj najważniejsze cechy",
  "296. Scharakteryzuj mecenat Stanisława Augusta i ogrody romantyczne",
  "297. Neoklasycyzm Królestwa Kongresowego - podaj najważniejsze cechy",
  "298. Historyzm i eklektyzm w architekturze polskiej - podaj najważniejsze cechy",
  "299. Secesja, style narodowe – do 1914 r. w architekturze polskiej",
  "300. Architektura Warszawy w latach międzywojennych",
  "301. Architektura Warszawy w okresie realizmu socjalistycznego",
  "302. Szeregowa kamienica hanzeatycka XIII-XVIII w. na terenie Polski - podaj najważniejsze cechy",
  "303. Dom miejski i małomiasteczkowy na południowych terenach Polski XIII- XVIII w. - podaj najważniejsze cechy",
  "304. Kamienica czynszowa XIX-XX w. w Warszawie, Łodzi - podaj najważniejsze cechy",
  "305. Dwór polski; zespoły dworskie, zabudowa wsi – XIX w.- podaj najważniejsze cechy",
  "306. Drewniana architektura sakralna; świątynie różnych wyznań XV-XIX w. na terenie Polski - podaj najważniejsze cechy",
  "307. Przejrzystość jako charakterystyczna cecha architektury – podaj pierwsze znane projekty i realizacje i przykłady współczesne.",
  "308. Systemy konstrukcji ściskanych i rozciąganych i ich ilustracja charakterystycznymi przykładami dzieł architektury współczesnej.",
  "309. Ruch architektury nowoczesnej w Polsce. Organizacje, główni przedstawiciele, dokonania, osiągnięcia.",
  "310. Formy architektury i ich znaczenie – przemiany poglądów na ten temat w oparciu o znane dzieła architektury XX w. i wypowiedzi teoretyczne.",
];

export const technika = [
  "312. Jakie są różnice funkcjonalne pomiędzy ścianą zewnętrzną konstrukcyjnoizolującą i izolującą?",
  "313. Jakie są rodzaje zewnętrznych ścian warstwowych i materiały stosowane w poszczególnych warstwach?",
  "314. Jakie materiały pozwalają na uzyskanie jednorodnej ściany zewnętrznej o wymaganej izolacyjności cieplnej?",
  "315. Jakie są zasady stosowania izolacji termicznej oraz jakie rozwiązania materiałowe w przegrodach poziomych: stropodachach, stropach nad bramami i przejazdami, podłogach na gruncie?",
  "316. Jakie są zasady stosowania izolacji termicznej w ścianach zewnętrznych oraz jakie wynikają z tego rozwiązania materiałowe? Jakie wyroby szklane stosowane są w budownictwie i jakie spełniają funkcje?",
  "317. Czym powinny charakteryzować się materiały stosowane na izolacje termiczne oraz na izolacje akustyczne? Proszę podać przykłady.",
  "318. Jakie materiały i wyroby stosuje się na pokrycia dachowe?",
  "319. Jakie są zasady kształtowania poszczególnych typów stropodachów i tarasów nad pomieszczeniami ogrzewanymi?",
  "320. Jakie warstwy i jakie materiały stosuje się w połaci dachowej nad poddaszem ogrzewanym, a jakie nad poddaszem nieogrzewanym?",
  "321. Jakie rodzaje posadzek stosowane są we wnętrzach publicznych? Jakie są ich cechy estetyczne i parametry techniczne?",
  "322. Co oznacza pojęcie „podłoga pływająca”? Jakie się w niej stosuje rozwiązania materiałowe?",
  "323. Jakie materiały stosuje się do izolacji przeciwwodnej i przeciwwilgociowej w części podziemnej budynku podpiwniczonego?",
  "324. W którym miejscu i w jaki sposób układana jest izolacja przeciwwodna w poszczególnych rodzajach stropodachów? Jakie materiały stosowane są na tę izolację?",
  "325. Jaka jest funkcja sufitów podwieszonych oraz jakie znasz rozwiązania techniczno-materiałowe?",
  "326. Jakie jest zastosowanie i rozwiązania techniczne posadzek drewnianych, w tym podłóg sprężystych?",
  "327. Podaj przykłady zastosowania wykładzin posadzkowych naturalnych i z tworzyw sztucznych.",
  "328. Podaj przykłady zastosowania posadzek kamiennych i ceramicznych. Jakie znasz rozwiązania techniczne?",
  "329. Jakie okładziny kamienie są stosowne do ścian zewnętrznych oraz jakie są sposoby ich mocowania?",
  "330. Jakie są zasady kształtowania i montowania licówki ceramicznej w ścianach zewnętrznych?",
  "331. Jakie mogą być efekty architektoniczne oraz rozwiązania techniczne związane z zastosowaniem tynku na elewacji?",
  "332. Jakie materiały mogą być stosowane do okładzin ścian zewnętrznych i jakie są sposoby montażu w tzw. „metodzie lekkiej suchej”?",
  "333. Jakie są zasady montażu i zabezpieczenia okładzin ścian zewnętrznych z drewna naturalnego?",
  "334. Jakie są charakterystyczne elementy współczesnej ślusarki okiennej i drzwiowej?",
  "335. Jakie są zasady mocowania poszczególnych typów ścian kurtynowych do podstawowej konstrukcji budynku?",
  "336. Jakie są efekty architektoniczne oraz problemy technologiczne zastosowania szkła na całej elewacji?",
  "337. Jakie rodzaje szyb stosuje się współcześnie ze względu na ochronę przeciwsłoneczną, cieplną, bezpieczeństwo i efekty wizualne?",
  "338. Zdefiniuj warunki równowagi układu sił na płaszczyźnie – analitycznie i graficznie",
  "339. Podaj definicje wartości charakterystycznej i obliczeniowej obciążenia. W jakich obliczeniach są one wykorzystywane.",
  "340. Ile razy jest mniejszy moment zginający w belce swobodnie podpartej obciążonej równomiernie od momentu zginającego w belce wspornikowej o takiej samej długości?",
  "341. Podaj definicje schematu statycznie wyznaczalnego i niewyznaczalnego. Podaj różnice tych schematów w przenoszeniu obciążeń (termiczne, nierównomierne osiadania podpór).",
  "342. Podaj definicje: belki, ramy, kratownicy i łuku. Jakie rodzaje sił wewnętrznych powstają w tych ustrojach w ogólnym stanie obciążenia?",
  "343. Podaj definicję i własności rdzenia przekroju. W jakich sytuacjach wyznaczamy rdzeń przekroju?",
  "344. Jakie rodzaje sił wewnętrznych występują w belkach? Od czego zależy ugięcie belki?",
  "345. Co to jest pręt krępy? Podaj podstawową różnicę w projektowaniu słupów ściskanych osiowo krępych i smukłych.",
  "346. Co to jest wyboczenie pręta? Od czego zależy?",
  "347. Określ warunki pracy statycznej łuku bezmomentowego. Jakie rodzaje sił wewnętrznych występują w takim łuku?",
  "348. Wymień rodzaje sił wewnętrznych w kopułach cienkościennych.",
  "349. Na podstawie jakich zależności można określić wstępne wymiary elementów konstrukcyjnych?",
  "350. Co oznacza pojęcie „klasa betonu”? Jak określa się wytrzymałość betonu na ściskanie?",
  "351. Wyjaśnić, na czym polega „współpraca” betonu i stali w konstrukcjach żelbetowych.",
  "352. Jakie znasz schematy statyczne typowych układów słupowo-belkowych?",
  "353. Podaj podstawowe proporcje przy kształtowaniu belek pełnościennych stalowych oraz z drewna klejonego.",
  "354. Uszereguj wg. nośności na ściskanie: dwuteownik, rura okrągła, rura kwadratowa.",
  "355. Uszereguj wg nośności na zginanie następujące profile stalowe o identycznej powierzchni przekroju poprzecznego: rura okrągła, rura kwadratowa, dwuteownik.",
  "356. Jakie czynniki mają wpływ na wyboczenie przy określaniu nośność elementów ściskanych?",
  "357. Proszę omówić podstawowe konstrukcje więźb dachowych.",
  "358. Podaj przykłady rozwiązań konstrukcyjno-materiałowych stropów gęstożebrowych.",
  "359. Podaj racjonalne proporcje grubości płyty żelbetowej do rozpiętości w zależności od warunków podparcia.",
  "360. Wyjaśnij, co oznaczają w wymiarowaniu konstrukcji pojęcia: stan graniczny nośności i użytkowalności?",
  "361. Jakie znasz rozwiązania konstrukcyjne schodów?",
  "362. Jakie znasz technologie realizacji budynków z żelbetu?",
  "363. Podaj pozytywne i negatywne cechy technologii monolitycznej i prefabrykowanej w konstrukcjach żelbetowych.",
  "364. Scharakteryzuj szkieletowe układy konstrukcyjne w budynkach wielokondygnacyjnych.",
  "365. Jakie są zasady usztywniania konstrukcji budynków halowych?",
  "366. Jakiego rodzaju zjawiska wywołują potrzebę stosowania dylatacji w konstrukcjach budowlanych?",
  "367. Podaj zasady pracy elementów struno- i kablobetonowych oraz przykłady zastosowania.",
  "368. Przedstaw sposoby fundamentowania głębokiego?",
  "369. Co to są betony wysokowartościowe.Jaki jest zakres ich zastosowania.",
  "370. Na jakiej podstawie określa się klasę odporności ogniowej dla budynku?",
  "371. Jakie są wymagania dotyczące określania klasy odporności ogniowej elementów konstrukcyjnych budynków?",
  "372. Jakie są zasady pracy lekkich przekryć wiszących- jednokrzywiznowych lub o krzywiznach tego samego znaku?",
  "373. Proszę omówić podstawowe cechy elementów konstrukcyjnych pozwalających na przenoszenie sił z konstrukcji przekryć namiotowych na grunt.",
  "374. Podaj przykłady wykorzystania zasady „tensegrity” w obiektach mobilnych.",
  "375. Podaj przykłady konstrukcji wspomaganych cięgnami. Na czym polega zasada ich działania.",
  "376. Problematyka projektowa posadowienia budynku poniżej poziomu wody gruntowej.",
  "377. Problematyka projektowa posadowienia budynku na skarpie.",
  "378. Problematyka projektowa posadowienia budynku na terenach słabonośnych.",
  "379. Problematyka projektowa posadowienia budynku o kilku kondygnacjach podziemnych.",
  "380. Jakie są cechy domu jednorodzinnego energooszczędnego typu pasywnego?",
  "381. Proszę omówić pojęcie budynku niskoenergochłonnego z punktu widzenia: realizacji idei zrównoważonego rozwoju i obowiązujących przepisów prawa budowlanego.",
  "382. Jakie podstawowe warunki muszą być spełnione, aby budynek mógł być określony mianem helioaktywnego?",
  "383. Proszę określić czynniki wpływające na jakość charakterystyki energetycznej budynku i podać, na które z nich ma wpływ architekt.",
  "384. Proszę omówić pojęcie „budownictwo zgodne z klimatem regionu”. Podać charakterystyczne przykłady.",
  "385. Proszę zdefiniować pojęcie komfortu cieplnego w pomieszczeniach użytkowych i omówić podstawowe parametry fizyczne z tym związane.",
  "386. Proszę zdefiniować pojęcie mostka cieplnego oraz podać klasyfikację i przykłady występowania w budynkach.",
  "387. Jakie są negatywne konsekwencje docieplenia ściany zewnętrznej od strony ogrzewanego pomieszczenia? W jaki sposób można ograniczyć lub wyeliminować niekorzystny efekt takiego działania?",
  "388. Proszę omówić pojęcia: termo-, paro- i wiatroizolacji zewnętrznych przegród budowlanych.",
  "389. Na jakiej zasadzie działa dynamiczna izolacja cieplna?",
  "390. Na czym polega i z jakich zabiegów składa się kompleksowa termomodernizacja budynku?",
  "391. Co należy rozumieć pod pojęciem technicznej infrastruktury miasta lub osiedla? Wymienić podstawowe elementy.",
  "392. Jakie są podstawowe elementy wchodzące w skład przyłączy: wodociągowego, kanalizacyjnego, ciepłowniczego?",
  "393. W jakich przypadkach w instalacji wodociągowej trzeba zastosować hydrofor? Proszę scharakteryzować miejsce jego lokalizacji?",
  "394. Proszę podać rodzaje oraz podstawowe wymagania dotyczące ujęcia wody pitnej.",
  "395. Co to jest kanalizacja ogólnospławna? Jakie mogą być inne rodzaje kanalizacji? Jakie są ich wady i zalety?",
  "396. Jakie są podstawowe kryteria wyboru źródła ciepła?",
  "397. Proszę opisać podstawowe systemy ogrzewania pomieszczeń w budynkach mieszkalnych i użyteczności publicznej.",
  "398. Jakie są zalety i wady stosowania ogrzewania powietrznego pomieszczeń mieszkalnych w porównaniu z centralnym ogrzewaniem wodnym?",
  "399. Na czym polega wentylacja naturalna. Jaki jest zakres stosowania wentylacji grawitacyjnej w budynkach mieszkalnych i użyteczności publicznej?",
  "400. W jakich budynkach mieszkalnych i użyteczności publicznej można stosować wentylację grawitacyjną?",
  "401. W jakich przypadkach można w obrębie jednego mieszkania łączyć wentylację grawitacyjną i mechaniczną?",
  "402. Proszę podać podstawowe wymagania oraz zasady lokalizacji czerpni i wyrzutni powietrza wentylacyjnego.",
  "403. W jakich przypadkach czerpnie i wyrzutnie nie muszą występować jako osobne wyposażenie?",
  "404. Jakie są podstawowe sposoby oświetlenia wnętrz światłem dziennym?",
  "405. Jakie są sposoby intensyfikacji naturalnego oświetlenia pomieszczeń głębokich lub rozległych?",
  "406. Co należy rozumieć pod pojęciem „klimat akustyczny wnętrza”?",
  "407. Proszę podaj przykłady rozwiązań technicznych stosowanych do ochrony budynków i ich wnętrz przed hałasem.",
  "408. Proszę podać rodzaje i funkcje kosztorysów.",
  "409. Jakie są elementy składowe zbiorczego zestawienia kosztów?",
  "410. Proszę podać podstawę i sposób określania wyceny prac projektowych.",
  "411. Podać podstawy opracowania i sposób sporządzania kosztorysu inwestorskiego.",
  "412. Proszę podać rodzaje wycen nieruchomości.",
  "413. Jaki jest zakres dokumentacji projektowej wymagany w celu uzyskania pozwolenia na budowę?",
  "414. Proszę omówić procedurę uzyskania pozwolenia na budowę w przypadku istniejącego planu miejscowego lub braku takiego planu.",
  "415. Proszę podać najważniejsze akty prawne i Ustawy, które są podstawą wykonywania zawodu architekta.",
  "416. Jaka jest podstawa prawna i sposób wykonywania miejscowych planów zagospodarowania przestrzennego?",
  "417. Co sprawdza „właściwy organ” zatwierdzający projekt budowlany i udzielający pozwolenia na budowę?",
  "418. Proszę wymienić i omówić kryteria związane z projektowaniem inwestycji.",
  "419. Kto jest uczestnikiem procesu inwestycyjnego?",
  "420. Co oznacza pojęcie organizacja procesu inwestycyjnego?",
  "421. Jaka jest odpowiedzialność uczestników w procesie inwestycyjnym?",
  "422. Proszę omówić elementy potrzebne do uzyskania informacji o okresie zwrotu inwestycji.",
  "423. Na czym polega „istota” dyskontowania.",
  "424. Co to jest analiza SWOT?",
  "425. Co to jest plan BIOZ? Kto sporządza plan BIOZ?",
  "426. Co oznacza termin 3R? Jaki wpływ ma zastosowanie powyższej strategii na rozwiązania urbanistyczne i architektoniczno-budowlane?",
  "427. Proszę wymienić, jakie są kierunki w zarządzaniu i omówić jeden z nich.",
  "428. Proszę wyjaśnić pojęcie „studium możliwości realizacji”-„feasibility study”.",
];
