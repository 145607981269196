import * as React from "react";
import { useState, useEffect } from "react";

const Counter3 = () => {
  const [count3, setCount3] = useState(0);

  const increase = () => {
    setCount3((prevCount) => {
      const newCount3 = Number(prevCount) + 1;
      localStorage.setItem("count3", newCount3);
      return newCount3;
    });
  };

  useEffect(() => {
    const initialValue = localStorage.getItem("count3");
    if (initialValue) setCount3(initialValue);
  }, []);

  return (
    <div>
      <button className="btn" onClick={increase}>
        <h4>Umiem: {count3} / 120</h4>
      </button>
    </div>
  );
};

export default Counter3;
