import * as React from "react";
import { useState, useEffect } from "react";

const Counter4 = () => {
  const [count4, setCount4] = useState(0);

  const increase = () => {
    setCount4((prevCount) => {
      const newCount4 = Number(prevCount) + 1;
      localStorage.setItem("count4", newCount4);
      return newCount4;
    });
  };

  useEffect(() => {
    const initialValue = localStorage.getItem("count4");
    if (initialValue) setCount4(initialValue);
  }, []);

  return (
    <div>
      <button className="btn" onClick={increase}>
        <h4>Umiem: {count4} / 116</h4>
      </button>
    </div>
  );
};

export default Counter4;
