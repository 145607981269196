import * as React from "react";
import { useState, useEffect } from "react";

const Counter2 = () => {
  const [count2, setCount2] = useState(0);

  const increase = () => {
    setCount2((prevCount) => {
      const newCount2 = Number(prevCount) + 1;
      localStorage.setItem("count2", newCount2);
      return newCount2;
    });
  };

  useEffect(() => {
    const initialValue = localStorage.getItem("count2");
    if (initialValue) setCount2(initialValue);
  }, []);

  return (
    <div>
      <button className="btn" onClick={increase}>
        <h4>Umiem: {count2} / 48</h4>
      </button>
    </div>
  );
};

export default Counter2;
