import * as React from "react";
import { useState } from "react";
import { architektura } from "./Data";
import "./Cards.css";
import Counter1 from "./Counter1";

export default function MediaCard() {
  const [card, setCard] = useState(-1);
  var myArray = architektura;
  var rand = myArray[(Math.random() * myArray.length) | 0];

  const nextCardOnClick = () => {
    setCard((prev) => (prev + 1) % 3);
  };

  return (
    <div className="card">
      <h4 className="title">ARCHITEKTURA</h4>
      <p>{rand}</p>
      <div className="buttons">
        <Counter1 />
        <button className="btn" onClick={nextCardOnClick}>
          Następne pytanie
        </button>
      </div>
    </div>
  );
}
