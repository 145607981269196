import * as React from "react";
import { useState, useEffect } from "react";

const Counter1 = () => {
  const [count1, setCount1] = useState(0);

  const increase = () => {
    setCount1((prevCount) => {
      const newCount1 = Number(prevCount) + 1;
      localStorage.setItem("count1", newCount1);
      return newCount1;
    });
  };

  useEffect(() => {
    const initialValue = localStorage.getItem("count1");
    if (initialValue) setCount1(initialValue);
  }, []);

  return (
    <div>
      <button className="btn" onClick={increase}>
        <h4>Umiem: {count1} / 140</h4>
      </button>
    </div>
  );
};

export default Counter1;
